@import "mixins";

/* Global styles */
.btn-pink {
    @include btn-pink;
}

.btn-badge {
    @include btn-badge;
}
.btn-outline-material {
    @inlcude btn-outline-material;
}

.centered-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Color classes */
.color-primary {
    color: $primary;
}

.color-secondary {
    color: $secondary;
}

.pink {
    color: $pink;
}
/* Font classes */
.font-title {
    font-family: $font-title;
}

.font-subtitle {
    font-family: $font-subtitle;
}

.font-content {
    font-family: $font-content;
}

/* Links */
.text-underline {
    text-decoration: underline;
}

a {
    color: $primary;
    &:hover, &:focus, &:active {
        color: $secondary;
        text-decoration: underline;
    }
}