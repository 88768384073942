@import "global";

/* Style */
body {
    background: url('../img/lueure.png') no-repeat 50% 239px, url('../img/pattern-body.png') repeat;
}

.subtitle {
    padding-left: 33%;
    color: rgba(255, 255, 255, 0.8);
    text-shadow: 1px 1px 1px $pink;
}

.hero {
    height: 480px;
}

hr.sep-hero {
    border-color: rgba(255, 255, 255, 0.1);
    margin-top: 7rem;
    padding-bottom: 2rem;
}

/* Card corners */
.card:before, .card:after{
    content:'';
    z-index:-1;
    width:200px;
    
    position:absolute;
    bottom:20px;
    left:10px;
    top:80%;
    
    box-shadow:0 20px 15px #000;
    transform:rotate(-5deg);
}

.card:after{
    left:auto;
    right:10px;
    transform:rotate(5deg);
}

.icon {
    font-size: 2rem;
    &.fa-html5 {
        color: #e44d26; 
    }
    &.fa-css3-alt {
        color: #264de4;
    }
}
