@import "variables";

/* Mixins */
@mixin btn-pink {
    font-family: $font-content;
    font-size: 1rem;
    background-color: $pink;
    border-radius: 4px;
    color: white;
    &:hover, &:focus, &:active {
        box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.3);
        color: white;
        background-color: $pink-hover;
    }
}

@mixin btn-badge {
    font-family: $font-content;
    font-size: 0.9rem;
    background-color: $secondary;
    border-radius: 20px;
    color: white;
    text-transform: uppercase;
    transition: all ease-in-out 0.3s;
    &.btn-badge-secondary {
        background-color: $purple;
    }
    &:hover, &:focus, &:active {
        box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.3);
        color: white;
        background-color: $purple;
    }
}

@mixin btn-outline-material {
    font-family: $font-content;
    text-transform: uppercase;
    color: $secondary;
    font-size: 1.1rem;
    background-color: none;
}