/* Colors */
$primary: #515151;
$secondary: #767676;
$pink: #ea1c60;
$pink-hover: #e6002e;
$purple: #982d9c;

/* Fonts */
$font-title: "Rock Salt", cursive;
$font-subtitle: "Roboto Slab", serif;
$font-content: "Roboto", sans-serif;